/*--------------------------------------------------------------
# Section
--------------------------------------------------------------*/
img {
  cursor: pointer;
}

section {
  padding: 40px 0;
  overflow: hidden;
}

.imagen {
  @media (min-width: 250px) and (max-width: 600px){
    width: 100%;
  }
  @media (min-width: 770px) and (max-width: 1000px){
    width: 80%;
  }
   @media (min-width: 1000px) and (max-width: 4000px){
    width: 50%;
  }
}

/*--------------------------------------------------------------
# Footer
--------------------------------------------------------------*/

footer {
  padding: 2rem 0;
  background-color: #ffffff;
}

.text-social-media {
  color: #666666;
  font-weight: bold;
  font-size: 16px;
}

.social-media-icons {
  width: 32px;
  font-size: 18px;
  color: #666666;
}

.social-media-icons:hover{
  color: #001D9A;
}

.link-page {
  font-size: 16px;
  color: #001D9A;
}

.link-page:hover{
  color: #E1001E;
}
